import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${props =>
    props.isRow &&
    `
      @media (min-width: 1024px) {
        flex-direction: row;
      }
    `};

  ${props =>
    props.hasBottomMargin &&
    `
      margin-bottom: 2rem;

      @media (min-width: 769px) {
        margin-bottom: 4rem;
      }
    `};

  width: 100%;
  max-width: ${props => (props.isFullWidth ? "100%" : "112rem")};
  padding: ${props => (props.isFullWidth ? "0" : "0 1rem")};
`

const Container = ({
  children,
  isRow = false,
  hasBottomMargin = false,
  isFullWidth = false,
  className,
}) => {
  return (
    <StyledContainer
      className={`${className} container`}
      isRow={isRow}
      hasBottomMargin={hasBottomMargin}
      isFullWidth={isFullWidth}
    >
      {children}
    </StyledContainer>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  isRow: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  hasBottomMargin: PropTypes.bool,
  className: PropTypes.string,
}

export default Container
