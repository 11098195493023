import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

const StyledNavIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding: 0.5rem 0;
  border-bottom: 0.1rem solid #cfcfcf;

  @media (min-width: 1023px) {
    position: relative;
    padding: 0;
    border: 0;
  }

  a {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;

    @media (min-width: 1024px) {
      &:first-child {
        font-size: 1.9rem;
      }
    }

    @media (max-width: 1024px) {
      &:first-child {
        margin-left: 0;
      }
    }

    &:hover {
      color: ${props => props.theme.crockpot.main};
    }
  }
`

const NavIcons = ({ navIcons }) => {
  if (!navIcons) return null

  return (
    <StyledNavIcons className="nav-icons">
      {navIcons
        ?.sort((a, b) => a.node.order - b.node.order)
        .filter(link => link.node.url !== "#")
        .map(({ node }) => {
          return (
            <a
              href={node.url}
              aria-label={node.name}
              target="_blank"
              rel="noopener noreferrer"
              key={node.contentful_id}
            >
              <FontAwesomeIcon
                icon={node.type === "facebook" ? faFacebook : faInstagram}
              />
            </a>
          )
        })}
    </StyledNavIcons>
  )
}

export default NavIcons
