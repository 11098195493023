const theme = {
  crockpot: {
    main: "#f2c75c",
    second: "#53565a",
    text: "#53565a",
    textSecond: "#051438",
    textAlt: "#fff",
    recipeOdd: "rgba(83, 86, 90, 0.8)",
    recipeOddFull: "rgba(83, 86, 90)",
    recipeEven: "rgba(97, 75, 121, 0.8)",
    recipeCategories: {
      All: "rgba(97, 75, 121, 0.8)",
      Multi: "rgba(97, 75, 121, 0.8)",
      Slow: "rgba(168, 81, 0, 0.8)",
      Christmas: "rgba(164, 1, 2, 0.8)",
    },
    recipeEvenFull: "rgba(97, 75, 121)",
    mainFont: "Roboto",
    secondFont: "RobotoBold",
    thirdFont: "zetafonts_-_blacker_pro_display_bold-webfont",
  },
}

export default theme
