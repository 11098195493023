import { TRANSLATIONS } from "./translations"
export const isSubdomain = () => {
  const langs = ["en", "es", "fr", "de"]
  return langs.indexOf(process.env.GATSBY_LOCALE) > -1
    ? process.env.GATSBY_LOCALE
    : null
}

export const getLanguage = () => {
  if (isSubdomain()) return ""
  if (typeof window === "undefined") return "es"
  if (window.location.pathname === "/") return "es"
  return `/${window.location.pathname.split("/")[1]}`
}
export const getTranslation = key => {
  const lang = getLanguage().split("/")[1]
  const locale = process.env.GATSBY_LOCALE

  return locale && locale !== "ch"
    ? TRANSLATIONS[locale][key]
    : TRANSLATIONS[lang ? lang : "es"][key]
}

export const contentfulLanguage = nodeLocale => {
  return nodeLocale === "en"
    ? "en-US"
    : nodeLocale === "fr"
      ? "fr-CH"
      : nodeLocale === "de"
        ? "de-CH"
        : nodeLocale === "it"
          ? "it-CH"
          : nodeLocale
}
