import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { navigate } from "gatsby"
import { getLanguage, getTranslation } from "../../utils"

const StyledInputContainer = styled.div`
  ${props =>
    props.isStatic
      ? `
        position:relative;
        width: 100%;

        input {
          height: 3.2rem !important;
          border: 0.1rem solid #959595;
          padding: 0.5rem 4.5rem 0.5rem 1rem;
          transition: 200ms linear;

          @media (min-width: 1024px) {
            height: 4.2rem !important;
            text-align: center;
            padding: 0.5rem 4.5rem;
          }
        }

        form {
          button {
            display: ${props.isSearchOpen ? "block" : "none"};
          }
        }
      `
      : `
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 1rem;
      width: ${props.isSearchOpen ? "55rem" : "0"};
      height: 3.6rem;

      ${
        props.isSearchOpen &&
        `
          @media (max-width: 1024px) {
            position: fixed;
            top: 3.8rem;
            right: 0;
            width: 100%;

            input {
              border-radius: 0 !important;
            }
          }

          @media (min-width: 1024px) {
            transition: 200ms ease-in !important;
          }
        `
      }

      input {
        height: 3.6rem;
        font-family: ${props.theme.crockpot.mainFont};
        border: 0;
        padding: 0.5rem 1rem;

        @media (min-width: 1024px) {
          padding: 0.5rem 1rem 0.5rem 4.5rem;
        }
      }
    `}

  @media (min-width: 1024px) {
    transition: 0ms ease-in;
  }

  z-index: 20;

  input {
    width: 100%;
    font-size: 1.5rem;
    color: ${props => props.theme.crockpot.textSecond};
    height: 3.6rem;
    border-radius: 0.4rem;
    -webkit-appearance: none;

    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }
`

const StyledLabel = styled.label``

const StyledSearchTriggerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${props => props.theme.crockpot.textSecond};
  color: #fff;
  font-size: 1.1rem;
  border: 0;
  border-radius: 0.3rem;
  z-index: 10;
  cursor: pointer;
  transition: 200ms linear;

  @media (min-width: 1024px) {
    font-size: 1.3rem;

    &:hover {
      background-color: ${props => props.theme.crockpot.text};
    }
  }

  svg {
    width: 0.8rem;

    path {
      fill: #fff;
    }
  }

  ${props =>
    props.isStatic
      ? `
        top: 0rem;
        right: 0rem;
        height: 3.2rem;
        width: 3.2rem;
        font-size: 2.2rem;
        padding-left: 1rem;

        @media (min-width: 1024px) {
          top: 0.1rem;
          right: 0.1rem;
          height: 4rem;
          width: 4rem;
          font-size: 2.8rem;
        }
      `
      : `
        top: 0.8rem;
        right: 0.8rem;
        height: 2rem;
        width: 2rem;
      `};

  &:disabled {
    opacity: 0.6;
    background: #cfcfcf !important;
    cursor: default;
  }
`

const StyledInput = styled.input`
  outline: none;

  &:focus,
  &:active {
    outline: none;
  }
`

const StyledInputIcon = styled.span`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0.8rem;
    height: 100%;
    z-index: 10;
  }

  svg {
    width: 2rem;
  }
`

const SearchInput = ({
  isSearchOpen,
  onSearchClose,
  isStatic,
  defaultSearchTerm,
}) => {
  const searchInput = useRef(null)
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm)

  const changeTerm = event => {
    setSearchTerm(event.target.value)
  }

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        onSearchClose()
      }
    },
    [onSearchClose],
  )

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  const triggerSearch = e => {
    e.preventDefault()

    onSearchClose()

    navigate(
      `${
        process.env.GATSBY_LOCALE?.includes("ch") || process.env.GATSBY_IS_SWISS
          ? getLanguage()
          : ""
      }/${getTranslation("search-results")}?search=${searchTerm}`,
    )
  }

  useEffect(() => {
    if (!isSearchOpen) return

    searchInput.current.focus()
  }, [isSearchOpen])

  useEffect(() => {
    setSearchTerm(defaultSearchTerm)
  }, [defaultSearchTerm])

  return (
    <StyledInputContainer isSearchOpen={isSearchOpen} isStatic={isStatic}>
      <StyledInputIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z" />
        </svg>
      </StyledInputIcon>
      <form action="" onSubmit={triggerSearch}>
        <StyledLabel>
          <StyledInput
            type="search"
            placeholder={getTranslation("enter-search-term")}
            onChange={changeTerm}
            ref={searchInput}
            value={searchTerm}
          />
          <StyledSearchTriggerButton
            type="submit"
            isStatic={isStatic}
            disabled={searchTerm?.length === 0}
            aria-label="Search"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
              <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
            </svg>
          </StyledSearchTriggerButton>
        </StyledLabel>
      </form>
    </StyledInputContainer>
  )
}

SearchInput.propTypes = {
  defaultSearchTerm: PropTypes.string,
  isSearchOpen: PropTypes.bool,
  isStatic: PropTypes.bool,
  onSearchClose: PropTypes.func,
}

SearchInput.defaultProps = {
  defaultSearchTerm: "",
  isSearchOpen: false,
  isStatic: false,
  onSearchClose: () => {},
}

export default SearchInput
