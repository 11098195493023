import React from "react"
import styled from "styled-components"
import { getTranslation } from "../../utils"

const StyledCopyrights = styled.p`
  color: ${props => props.theme.crockpot.textAlt};
  line-height: 1.5rem;
  margin: 0;

  @media (max-width: 769px) {
    line-height: 1.2rem;
  }
`

const Copyrights = () => {
  return (
    <StyledCopyrights data-testid="footer-copyrights">
      © {new Date().getFullYear()} Sunbeam Products, Inc.
      <br /> {getTranslation("all-rights-reserved")}
    </StyledCopyrights>
  )
}

export default Copyrights
