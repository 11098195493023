import React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"
import theme from "../theme/theme"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Landing from "../components/landing"
import "./layout.css"

const StyledMain = styled.main`
  overflow: hidden;
  padding-top: 3.6rem;

  @media (min-width: 1025px) {
    padding-top: 6rem;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = props => {
  return process.env.GATSBY_IS_LANDING ? (
    <Landing />
  ) : (
    <ThemeProvider theme={theme}>
      <StyledWrapper>
        <Header
          siteTitle={props.pageContext.pageData?.siteMetadata?.title || `Title`}
          mainMenuItems={props.pageContext.mainMenuItems}
          navIcons={props.pageContext.navIcons}
        />
        <StyledMain>{props.children}</StyledMain>
        <Footer footerItems={props.pageContext.footerItems} />
      </StyledWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
