const TRANSLATIONS = {
  en: {
    title: "Make good food everyday",
    description:
      "Crockpot slow cookers, multi-cookers and pressure cookers do the cooking… while you make the most of your day!",
    "all-rights-reserved": "All Rights Reserved",
    recipes: "recipes",
    category: "category",
    products: "products",
    "recommended-products": "Recommended Products",
    "recommended-recipes": "Recommended Recipes",
    "search-results": "search-results",
    "search-results-title": "Search results",
    "get-the-recipe": "Get the Recipe",
    "all-recipes": "All Recipes",
    "featured-products": "Featured Products",
    "where-to-buy": "Where to Buy",
    "where-to-buy-link": "where-to-buy",
    "download-the-recipe": "Download the Recipe",
    "download-pdf": "Download as a PDF",
    "enter-search-term": "enter search term here...",
    "end-of-results": "End of Results",
    "search-again": "Search again",
    "not-found": "Page Not Found",
    all: "All",
    "404-info":
      "Sorry, but we couldn’t find the page you are looking for. The address may have been typed incorrectly, or the page may no longer exist. Check to make sure you’ve typed the URL correctly.",
    "first-name": "First Name",
    "last-name": "Last Name",
    phone: "Phone",
    "e-mail": "E-Mail",
    "address-1": "Address 1",
    "address-2": "Address 2",
    country: "Country",
    city: "City",
    county: "County",
    "postal-code": "Postal Code",
    "date-code": "Date Code",
    "model-number": "Model Number",
    subject: "Subject",
    message: "Message",
    "attach-documents": "Attach documents",
    language: "English",
    failed: "Something went wrong, please try again later",
    success:
      "Your message has been sent. We will contact you shortly if your message requires a response.",
    "required-field": "Required field",
    "max-length": "Max length reached",
    "you-have": "You have",
    "characters-limit": "characters remaining out of a 500 character limit.",
    "code-info":
      "The date code is usually found on the rating label. Occasionally, this is located elsewhere on the product. This code should begin with one letter, followed by three numbers. (e.g.: A123)",
    "choose-file": "Choose File",
    "attach-file": "Attach File",
    submit: "Submit",
    "upload-info": "If needed, you can attach a document/image.",
    "upload-file-sizes":
      'File size should not exceed 3MB and file format should be "pdf,txt,doc,docx,jpg,png,jpeg,gif".',
    "more-products": "More Products",
    "all-products": "All Products",
    "multi-cookers": "Multi Cookers",
    "slow-cookers": "Slow Cookers",
    "empty-captcha": "Please select the reCaptcha",
  },
  es: {
    title: "Comida deliciosa y salubable, todos los días",
    description:
      "Las ollas de cocción lenta y multi-cookers Crockpot cocinan por ti… ¡mientras aprovechas tu día al máximo!",
    "all-rights-reserved": "Todos los derechos reservados",
    recipes: "recetas",
    category: "categoría",
    products: "productos",
    "recommended-products": "Productos recomendados",
    "recommended-recipes": "Recetas recomendadas",
    "search-results": "resultados-de-la-búsqueda",
    "search-results-title": "Resultados de la búsqueda",
    "get-the-recipe": "Accede a la receta",
    "all-recipes": "Todas las recetas",
    "featured-products": "Productos destacados",
    "where-to-buy": "Dónde comprar",
    "where-to-buy-link": "dónde-comprar",
    "download-the-recipe": "Descarga la receta",
    "download-pdf": "Descargar como PDF",
    "enter-search-term": "Ingrese el término de búsqueda aquí...",
    "enter-search-term-service": "Buscar servicio técnico",
    "end-of-results": "Fin de búsqueda",
    "search-again": "Busca de nuevo",
    "not-found": "Página No Encontrada",
    all: "Todos",
    "all-2": "Todas",
    "404-info":
      "Lo sentimos, pero no pudimos encontrar la página que busca. Es posible que la dirección se haya escrito incorrectamente o que la página ya no exista.",
    "first-name": "Nombre",
    "last-name": "Apellidos",
    phone: "Número de teléfono",
    "e-mail": "Dirección de email",
    "address-1": "Dirección postal 1",
    "address-2": "Dirección postal 2",
    country: "País",
    city: "Ciudad",
    county: "Distrito",
    "postal-code": "Código postal",
    "date-code": "Código de fecha",
    "model-number": "Número de modelo",
    subject: "Asunto",
    message: "Mensaje",
    "attach-documents": "Adjuntar documentos",
    language: "Spanish",
    failed: "Algo ha fallado, por favor inténtelo más tarde",
    success:
      "Su mensaje ha sido enviado. Nos pondremos en contacto con usted en breves para dar respuesta a su mensaje.",
    "required-field": "Información requerida",
    "max-length": "Longitud máxima",
    "you-have": "Quedan",
    "characters-limit": "caracteres de un límite de 500 caracteres.",
    "code-info":
      "El código de fecha generalmente se encuentra en la etiqueta de clasificación. Ocasionalmente, esto se encuentra en otra parte del producto. Este código debe comenzar con una letra seguida de tres números. (por ejemplo: A123)",
    "choose-file": "Elegir archivo",
    "attach-file": "Adjuntar archivo",
    submit: "Enviar",
    "upload-info": "Si es necesario, se puede adjuntar un documento o imagen",
    "upload-file-sizes":
      'El tamaño máximo de los archivos es de 3MB y el formato debe ser "pdf,txt,doc,docx,jpg,png,jpeg,gif"',
    "more-products": "Más productos",
    "all-products": "Todos los productos",
    "multi-cookers": "Multicookers",
    "slow-cookers": "Cocción Lenta",
    "empty-captcha": "Please select the reCaptcha",
    "more-recipes": "Más recetas",
    "more-recipes-url": "más-recetas",
  },
  fr: {
    title: "Réalisez des plats délicieux tous les jours",
    description:
      "Les mijoteuses, multicuiseurs et autocuiseurs électriques Crockpot font la cuisine à votre place... pendant que vous profitez pleinement de votre journée !",
    "all-rights-reserved": "Tous droits réservés",
    recipes: "recettes",
    category: "catégorie",
    products: "produits",
    "recommended-products": "Produits recommandés",
    "recommended-recipes": "Recettes recommandées",
    "search-results": "résultats-de-recherche",
    "search-results-title": "Résultats de recherche",
    "get-the-recipe": "Obtenir la recette",
    "all-recipes": "Toutes nos recettes",
    "featured-products": "Produits principaux",
    "where-to-buy": "Où acheter ?",
    "where-to-buy-link": "où-acheter?",
    "download-the-recipe": "Télécharger la recette",
    "download-pdf": "Télécharger en PDF",
    "enter-search-term": "Rechercher",
    "end-of-results": "Aucun résultat",
    "search-again": "Chercher à nouveau",
    "not-found": "Page non trouvée",
    all: "Tous",
    "all-2": "Toutes",
    "404-info":
      "Désolé, nous ne parvenons pas à trouver la page que vous recherchez. Il se peut que l'adresse soit erronée, ou que la page n'existe plus. Vérifiez que vous avez tapé l'URL correctement.",
    "first-name": "Prénom",
    "last-name": "Nom",
    phone: "Téléphone",
    "e-mail": "Adresse e-mail",
    "address-1": "Adresse 1",
    "address-2": "Adresse 2",
    country: "Pays",
    city: "Ville",
    county: "Comté",
    "postal-code": "Code postal",
    "date-code": "Code de date",
    "model-number": "Modèle",
    subject: "Sujet",
    message: "Message",
    "attach-documents": "Joindre des documents",
    language: "French",
    failed: "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
    success:
      "Votre message a été envoyé avec succès. Nous vous contacterons dans un court délai si votre message nécessite une réponse.",
    "required-field": "Le champ est obligatoire",
    "max-length": "Longueur maximale dépassée",
    "you-have": "Il vous reste ",
    "characters-limit": "caractères sur une limite de 500 caractères.",
    "code-info":
      "Le code de date se trouve généralement sur l'étiquette signalétique. Il peut également se trouver autre part sur le produit. Ce code doit commencer par une lettre, suivie de trois chiffres. (par exemple: A123)",
    "choose-file": "Choisir un fichier",
    "attach-file": "Joindre un fichier",
    submit: "Accepter",
    "upload-info":
      "Si nécessaire, vous pouvez joindre un document / une image.",
    "upload-file-sizes":
      'La taille du fichier ne doit pas dépasser 3 Mo et le format du fichier doit être "pdf, txt, doc, docx, jpg, png, jpeg, gif".',
    "more-products": "Plus de produits",
    "all-products": "Tous les produits",
    "multi-cookers": "Multicuiseurs",
    "slow-cookers": "Mijoteuses",
    "empty-captcha": "Please select the reCaptcha",
    "more-recipes": "Plus de recettes",
    "more-recipes-url": "plus-de-recettes",
  },
  de: {
    title: "Jeden Tag lecker kochen",
    description:
      "Crockpot Schongarer, Multi-Kocher und Elektrische Schnellkochtöpfe übernehmen das Kochen… während Sie das Beste aus Ihrem Tag machen!",
    "all-rights-reserved": "Alle Rechte vorbehalten",
    recipes: "rezepte",
    category: "kategorie",
    products: "produkte",
    "recommended-products": "Hervorragend Produkte",
    "recommended-recipes": "Empfohlene Rezepte",
    "search-results": "suchergebnisse",
    "search-results-title": "Suchergebnisse",
    "get-the-recipe": "Das Rezept erhalten",
    "all-recipes": "Alle Rezepte",
    "featured-products": "vorgestellte Produkte",
    "where-to-buy": "Händlersuche",
    "where-to-buy-link": "händlersuche",
    "download-the-recipe": "Das Rezept downloaden",
    "download-pdf": "Als PDF herunterladen",
    "enter-search-term": "Suchbegriff hier eingeben...",
    "end-of-results": "Ende der Resultate",
    "search-again": "Erneut suchen",
    "not-found": "Seite nicht gefunden",
    all: "Alle",
    "404-info":
      "Es tut uns leid, aber wir konnten die Seite, die Sie suchen, nicht finden. Die Adresse wurde möglicherweise falsch eingegeben, oder die Seite existiert nicht mehr. Vergewissern Sie sich, dass Sie die URL richtig eingegeben haben.",
    "first-name": "Vorname",
    "last-name": "Nachname",
    phone: "Telefon",
    "e-mail": "E-Mail",
    "address-1": "Adresse",
    "address-2": "Adresszusatz",
    country: "Land",
    city: "Stadt",
    county: "Bundesland / Kanton",
    "postal-code": "PLZ",
    "date-code": "Datumscode",
    "model-number": "Produktmodell",
    subject: "Betreff",
    message: "Nachricht",
    "attach-documents": "Dokumente anhängen",
    language: "German",
    failed:
      "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal.",
    success:
      "Ihre Nachricht wurde gesendet. Wir werden uns in Kürze bei Ihnen melden, wenn Ihre Nachricht eine Antwort erfordert.",
    "required-field": "Feld ist erforderlich.",
    "max-length": "Maximale Länge überschritten",
    "you-have": "Sie haben",
    "characters-limit": "Zeichen übrig von einem Limit von 500 Zeichen.",
    "code-info":
      "Der Datumscode befindet sich normalerweise auf dem Bewertungsetikett. Gelegentlich befindet sich dies an einer anderen Stelle des Produkts. Dieser Code sollte mit einem Buchstaben beginnen, gefolgt von drei Zahlen. (d.h. 1,2,3)",
    "choose-file": "Datei auswählen",
    "attach-file": "Datei anhängen",
    submit: "Einreichen",
    "upload-info": "Bei Bedarf können Sie ein Dokument / Bild anhängen.",
    "upload-file-sizes":
      'Die Dateigröße sollte 3 MB nicht überschreiten und das Dateiformat sollte sein "pdf,txt,doc,docx,jpg,png,jpeg,gif".',
    "more-products": "More Products",
    "all-products": "Alle Produkte",
    "multi-cookers": "Multikocher",
    "slow-cookers": "Schongarer",
    "empty-captcha": "Please select the reCaptcha",
  },
  it: {
    title: "Preparare del buon cibo ogni giorno",
    description:
      "Le pentole a cottura lenta, i multicooker e le pentole a pressione elettriche Crockpot cucinano per te... mentre ti godi al massimo la tua giornata",
    "all-rights-reserved": "Tutti i diritti riservati",
    recipes: "ricette",
    category: "categoria",
    products: "prodotti",
    "recommended-products": "Prodotti consigliati",
    "recommended-recipes": "Ricette consigliate",
    "search-results": "risultati-della-ricerca",
    "search-results-title": "Risultati della ricerca",
    "get-the-recipe": "Ottieni la ricetta",
    "all-recipes": "Tutte le ricette",
    "featured-products": "Prodotti presentati",
    "where-to-buy": "Dove acquistare",
    "where-to-buy-link": "dove-acquistare",
    "download-the-recipe": "Scarica la ricetta",
    "download-pdf": "Als PDF herunterladen",
    "enter-search-term": "Inserisci qui il termine da cercare...",
    "end-of-results": "Fine dei risultati",
    "search-again": "Erneut suchen",
    "not-found": "Pagina non trovata",
    all: "Tutte",
    "404-info":
      "Siamo spiacenti, ma non è stato possibile trovare la pagina cercata. L'indirizzo potrebbe essere stato digitato in modo errato, o la pagina potrebbe non esistere più. Verificare di avere digitato correttamente l’URL. È inoltre possibile utilizzare la funzione di ricerca per trovare quanto cercato.",
    "first-name": "Vorname",
    "last-name": "Nachname",
    phone: "Telefon",
    "e-mail": "E-Mail",
    "address-1": "Adresse",
    "address-2": "Adresszusatz",
    country: "Land",
    city: "Stadt",
    county: "Bundesland / Kanton",
    "postal-code": "PLZ",
    "date-code": "Datumscode",
    "model-number": "Produktmodell",
    subject: "Betreff",
    message: "Nachricht",
    "attach-documents": "Dokumente anhängen",
    language: "German",
    failed:
      "Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal.",
    success:
      "Ihre Nachricht wurde gesendet. Wir werden uns in Kürze bei Ihnen melden, wenn Ihre Nachricht eine Antwort erfordert.",
    "required-field": "Feld ist erforderlich.",
    "max-length": "Maximale Länge überschritten",
    "you-have": "Sie haben",
    "characters-limit": "Zeichen übrig von einem Limit von 500 Zeichen.",
    "code-info":
      "Der Datumscode befindet sich normalerweise auf dem Bewertungsetikett. Gelegentlich befindet sich dies an einer anderen Stelle des Produkts. Dieser Code sollte mit einem Buchstaben beginnen, gefolgt von drei Zahlen. (d.h. 1,2,3)",
    "choose-file": "Datei auswählen",
    "attach-file": "Datei anhängen",
    submit: "Einreichen",
    "upload-info": "Bei Bedarf können Sie ein Dokument / Bild anhängen.",
    "upload-file-sizes":
      'Die Dateigröße sollte 3 MB nicht überschreiten und das Dateiformat sollte sein "pdf,txt,doc,docx,jpg,png,jpeg,gif".',
    "more-products": "More Products",
    "all-products": "All Products",
    "multi-cookers": "Multicooker",
    "slow-cookers": "Pentole a cottura lenta",
    "empty-captcha": "Please select the reCaptcha",
  },
}

module.exports = {
  TRANSLATIONS,
}
