import React from "react"
import styled from "styled-components"

const LandingElement = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
    text-align: center;
    padding: 2.4rem 2rem;
    background: #fff;
    border-bottom: 0.3rem solid #f2c75c;
  }

  @media (min-width: 769px) {
    header {
      padding: 4.8rem 2rem;
    }
  }

  header img {
    width: 100%;
    max-width: 53rem;
    margin: 0;
  }

  main {
    padding-bottom: 4rem;
  }

  .language-flags-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 2rem;

    @media (min-width: 769px) {
      header {
        padding-top: 4rem;
      }
    }
  }

  .language-item {
    position: relative;
    text-align: center;
    padding: 0 2.5rem 4.5rem;

    @media (min-width: 769px) {
      padding: 0 2.5rem 6rem;
    }

    img {
      &.small-flag {
        @media (max-width: 769px) {
          margin-top: 0.8rem;
        }
      }

      &:not(.small-flag) {
        margin-bottom: 0.7rem;
      }
    }
  }

  @media (min-width: 769px) {
    .language-flags-container {
      padding-top: 12rem;
    }

    main {
      padding-bottom: 9rem;
    }
  }

  .language-item > span,
  .language-item > a {
    position: relative;
    display: inline-block;
    width: 6rem;

    @media (min-width: 769px) {
      width: 10rem;
    }
  }

  @media (min-width: 769px) {
    .language-item > a::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      content: "";
      opacity: 0;
      z-index: 0;
      transition: opacity 300ms linear;
      transform: translate3D(-50%, -50%, 0);
      box-shadow: 0 0 1.6rem #00000065;
      margin-top: -0.5rem;
    }
  }

  .language-item > a:hover::after {
    opacity: 1;
  }

  .language-name {
    position: relative;
    top: -0.8rem;
    color: #53565a;
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: 600;
    margin: 0;

    @media (min-width: 769px) {
      width: 10rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  .language-links {
    position: absolute;
    bottom: 2rem;
    display: flex;
    left: 50%;
    font-size: 0;
    transform: translate3d(-50%, 0, 0);
  }

  .language-links a {
    display: inline-block;
    position: relative;
    margin: 0 0.6rem;
  }

  .language-links.txt a {
    width: 1.8rem;
    height: 1.6rem;
    font-size: 0.9rem;
    line-height: 1.6rem;
    background: #cfcfcf;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 0.4rem;
    transition: 200ms linear;

    @media (min-width: 769px) {
      width: 2.7rem;
      height: 2.5rem;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }
  }

  .language-links.txt a:hover {
    background-color: #d80027;
  }

  .language-links .icon-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 200ms linear;
    margin: 0;
  }

  .language-links .icon-default {
    margin: 0;
  }

  .language-links a:hover .icon-hover {
    opacity: 1;
  }

  .small-flag {
    max-width: 4.5rem;
    max-height: 4.5rem;
    margin: 1.3rem 0 1.5rem;
    border-radius: 54%;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

    @media (min-width: 769px) {
      max-width: 7.2rem;
      max-height: 7.2rem;
      margin: 1.3rem 0 2.2rem;
    }
  }

  footer {
    color: #fff;
    font-size: 1.1rem;
    background: #53565a;
    padding: 2rem;
    margin-top: auto;
  }

  .footer-container {
    display: flex;
    max-width: 100rem;
    margin: 0 auto;
  }

  .footer-menu {
    display: flex;
    list-style-type: none;
    margin: 0 0 0 auto;
    padding: 0;
  }

  .footer-menu li {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  .footer-menu li:first-child {
    margin-left: 0;
  }

  .footer-menu li a {
    color: #fff;
    text-decoration: none;
    transition: all 300ms linear;
  }

  .footer-menu li a:hover {
    color: #051438;
  }

  @media (max-width: 600px) {
    .footer-menu {
      flex-direction: column;
      margin-left: auto;
    }

    .footer-menu li {
      margin-left: 0;
    }
  }
`

const Landing = () => {
  return (
    <LandingElement>
      <header>
        <img src="img/crockpot-logo.png" alt="Crockpot" />
      </header>
      <main>
        <section className="languages-section">
          <div className="language-flags-container">
            <div className="language-item">
              <span>
                <img
                  src="img/switzerland.png"
                  alt="CHE"
                  className="small-flag"
                />
              </span>
              <p className="language-name">CHE</p>
              <div className="language-links txt">
                <a href={process.env.FR_CH_URL} rel="noopener noreferrer">
                  Fr
                </a>
                <a href={process.env.DE_CH_URL} rel="noopener noreferrer">
                  De
                </a>
                <a href={process.env.IT_CH_URL} rel="noopener noreferrer">
                  It
                </a>
              </div>
            </div>
            <div className="language-item">
              <a href={process.env.DE_URL}>
                <img src="img/germany.png" alt="DE" className="small-flag" />
              </a>
              <p className="language-name">DEU</p>
            </div>
            <div className="language-item">
              <a href={process.env.ES_URL}>
                <img
                  src="img/spain.png"
                  srcset="img/spain.png 1x, img/spain@2x.png 2x"
                  alt="ESP"
                />
              </a>
              <p className="language-name">ESP</p>
              <div className="language-links">
                <a
                  href="https://www.facebook.com/crockpotespana"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/facebook.svg"
                    className="icon-default"
                    alt="Facebook"
                  />
                  <img
                    src="img/icons/facebook-hover.svg"
                    className="icon-hover"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/crockpot_espana/"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/instagram.svg"
                    className="icon-default"
                    alt="Instagram"
                  />
                  <img
                    src="img/icons/instagram-hover.png"
                    className="icon-hover"
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
            <div className="language-item">
              <a href={process.env.FR_URL}>
                <img
                  src="img/france.png"
                  srcset="img/france.png 1x, img/france@2x.png 2x"
                  alt="FRA"
                />
              </a>
              <p className="language-name">FRA</p>
              <div className="language-links">
                <a
                  href="https://www.facebook.com/crockpotfr/"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/facebook.svg"
                    className="icon-default"
                    alt="Facebook"
                  />
                  <img
                    src="img/icons/facebook-hover.svg"
                    className="icon-hover"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/crockpot_france/?hl=fr"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/instagram.svg"
                    className="icon-default"
                    alt="Instagram"
                  />
                  <img
                    src="img/icons/instagram-hover.png"
                    className="icon-hover"
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
            <div className="language-item">
              <a href={process.env.EN_URL}>
                <img
                  src="img/united-kingdom.png"
                  alt="GBR"
                  className="small-flag"
                />
              </a>
              <p className="language-name">GBR</p>
              <div className="language-links">
                <a
                  href="https://www.facebook.com/CrockPot.uk/"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/facebook.svg"
                    className="icon-default"
                    alt="Facebook"
                  />
                  <img
                    src="img/icons/facebook-hover.svg"
                    className="icon-hover"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/crockpotuk/"
                  rel="noopener noreferrer"
                >
                  <img
                    src="img/icons/instagram.svg"
                    className="icon-default"
                    alt="Instagram"
                  />
                  <img
                    src="img/icons/instagram-hover.png"
                    className="icon-hover"
                    alt="Instagram"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-container">
          <div className="footer-copyrights">
            © 2021 Sunbeam Products,
            <br />
            Inc. All Rights Reserved.
          </div>

          <ul className="footer-menu">
            <li>
              <a
                href="https://www.newellbrands.com/uk-modern-slavery-act-statement"
                rel="noopener noreferrer"
              >
                Modern Slavery Act Transparency
              </a>
            </li>
            <li>
              <a
                href="https://privacy.newellbrands.com/index_en.html"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://www.newellbrands.com/terms-of-use"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </LandingElement>
  )
}

export default Landing
